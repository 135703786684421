import { useCallback, useEffect, useRef } from 'react';

import { patchHistoryApi } from './historyApiMonkeyPatching';

const historyEvents = ['popstate', 'pushstate', 'replacestate'];

export const useUrlChange = (
  pageviewEventTrigger: () => void,
  pageloadedEventTrigger?: () => void,
  triggerPageLoaded: boolean = true,
) => {
  patchHistoryApi();
  const isBrowser = typeof window !== 'undefined';

  const urlOfPageviewEventTrigger = useRef('');
  const urlOfPageloadedEventTrigger = useRef('');

  const callback = useCallback(() => {
    if (!isBrowser) return;
    const newUrl = window.location.pathname;

    if (newUrl !== urlOfPageviewEventTrigger.current) {
      pageviewEventTrigger();
      urlOfPageviewEventTrigger.current = newUrl;
    }
    if (pageloadedEventTrigger && newUrl !== urlOfPageloadedEventTrigger.current && triggerPageLoaded) {
      pageloadedEventTrigger();
      urlOfPageloadedEventTrigger.current = newUrl;
    }
  }, [pageviewEventTrigger, pageloadedEventTrigger, triggerPageLoaded, isBrowser]);

  useEffect(() => {
    if (!isBrowser) return;
    callback();
    const handleHistoryEvent = () => callback();
    historyEvents.forEach((e) => window.addEventListener(e, handleHistoryEvent));
    return () => {
      historyEvents.forEach((e) => window.removeEventListener(e, handleHistoryEvent));
    };
  }, [callback]);
};
