import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import { getMarketingParams } from '@insify/ui-utils';

import type { MarketingParams } from '@insify/ui-utils';

type MarketingParamsContextValue = {
  marketingParams?: MarketingParams;
};

type MarketingParamsProviderProps = {
  children: React.ReactNode;
};

const MarketingParamsContext = createContext<MarketingParamsContextValue>({});

export const MarketingParamsProvider: React.FC<MarketingParamsProviderProps> = ({ children }): JSX.Element => {
  const [marketingParams] = useState<MarketingParams | undefined>(getMarketingParams());

  return <MarketingParamsContext.Provider value={{ marketingParams }}>{children}</MarketingParamsContext.Provider>;
};

export const useMarketingParams = (): MarketingParamsContextValue => {
  const context = useContext(MarketingParamsContext);
  if (context === undefined) throw new Error('useMarketingParams must be used within a MarketingParamsProvider');
  return context;
};
