// extracted by mini-css-extract-plugin
export var animatedWrapper = "Modal-module--animatedWrapper--7d7bb";
export var backButton = "Modal-module--backButton--d6bbd";
export var bottomButton = "Modal-module--bottomButton--72d03";
export var closeButton = "Modal-module--closeButton--eb08a";
export var content = "Modal-module--content--2cce1";
export var footer = "Modal-module--footer--70a56";
export var header = "Modal-module--header--8cf55";
export var restrictedHeight = "Modal-module--restrictedHeight--72f2f";
export var show = "Modal-module--show--6a66f";
export var withBorder = "Modal-module--withBorder--5eaa2";