import i18next from 'i18next';
import React from 'react';
import * as ReactI18next from 'react-i18next';

import { ExitPopup } from '~components/ExitPopup';
import { AxeptioListener } from '@insify/axeptio-listener';
import { ObjectivTrackerProvider } from '~components/ObjectivTrackerProvider';

import type { Country } from '~models/country';
import { Script, type WrapPageElementBrowserArgs } from 'gatsby';
import type { Resource } from 'i18next';

export type PageContext = {
  [key: string]: unknown;
  language: Country;
  i18nResources: Resource;
};

export const wrapWithProvider = ({ element, props }: WrapPageElementBrowserArgs<unknown, PageContext>) => {
  const i18n = i18next
    .createInstance({
      lng: props.pageContext.language,
      interpolation: {
        prefix: '[[__',
        suffix: '__]]',
        escapeValue: false,
      },
      initImmediate: false,
      resources: props.pageContext.i18nResources,
    })
    .use(ReactI18next.initReactI18next);
  void i18n.init();

  return (
    <AxeptioListener>
      {(isConsentGiven) => (
        <ObjectivTrackerProvider isConsentGiven={isConsentGiven}>
          <ReactI18next.I18nextProvider i18n={i18n}>
            {element}
            <ExitPopup />
            <Script src='https://flackr.github.io/scroll-timeline/dist/scroll-timeline.js' />
          </ReactI18next.I18nextProvider>
        </ObjectivTrackerProvider>
      )}
    </AxeptioListener>
  );
};
