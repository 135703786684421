import { makeContentContext } from '@objectiv/schema';
import { ObjectivProvider, ReactTracker, useTracker, useVisibleEventTracker } from '@objectiv/tracker-react';
import { SnowplowBrowserTransport } from '@objectiv/transport-snowplow-browser';
import { disableAnonymousTracking, newTracker } from '@snowplow/browser-tracker';
import { useEffect } from 'react';

import { useUrlChange } from './useUrlChange';

import type { TrackerConfig, TrackerEvent } from '@objectiv/tracker-core';

const SNOWPLOW_TRACKER_POSTPATH = '/obj/t';
const snowplowAnonymousTrackingOptions = {
  withServerAnonymisation: true,
  withSessionTracking: true,
};

const TrackerAnonymousModeSwitcher = ({ isConsentGiven }: { isConsentGiven: boolean }) => {
  const tracker = useTracker();

  useEffect(() => {
    if (isConsentGiven) {
      tracker.setAnonymous(false);
      disableAnonymousTracking();
    }
  }, [isConsentGiven]);

  return null;
};

const PageView = () => {
  const PAGEVIEW_CONTEXT_ID = 'pageview';
  const trackPageview = useVisibleEventTracker({
    locationStack: [makeContentContext({ id: PAGEVIEW_CONTEXT_ID })],
  });

  useUrlChange(trackPageview);
  return null;
};

type Props = {
  applicationId: string;
  isProd: boolean;
  active?: boolean;
  isConsentGiven: boolean;
  plugins: TrackerConfig['plugins'];
  children: React.ReactNode;
  isLogEnabled?: boolean;
  landingPages?: boolean;
};

export const InsifyObjectivProvider = ({
  applicationId,
  isProd,
  active = true,
  isConsentGiven,
  plugins,
  children,
  isLogEnabled = false,
  landingPages,
}: Props) => {
  // Creat tracker instance
  newTracker('sp', isProd ? 'https://collector.prod.insify.io' : 'https://collector.staging.insify.io', {
    appId: applicationId,
    eventMethod: 'post',
    anonymousTracking: isConsentGiven ? snowplowAnonymousTrackingOptions : false,
    stateStorageStrategy: 'cookie',
    contexts: {
      webPage: true,
    },
    postPath: SNOWPLOW_TRACKER_POSTPATH,
  });

  const Transport = isLogEnabled ? TransportWithLogs : SnowplowBrowserTransport;

  const tracker = new ReactTracker({
    applicationId,
    // Use anonymous mode until user gives a consent
    anonymous: !isConsentGiven,
    active,
    transport: new Transport(),
    trackHttpContext: false,
    plugins,
  });

  return (
    <ObjectivProvider tracker={tracker}>
      {landingPages && typeof window !== 'undefined' && <PageView />}
      <TrackerAnonymousModeSwitcher isConsentGiven={isConsentGiven} />
      {children}
    </ObjectivProvider>
  );
};

type Handler = InstanceType<typeof SnowplowBrowserTransport>['handle'];

const normalizeEvent = (event: TrackerEvent | Promise<TrackerEvent>): Promise<TrackerEvent> => {
  return Promise.resolve(event);
};

class TransportWithLogs extends SnowplowBrowserTransport {
  constructor() {
    super();
  }

  handle(...args: Parameters<Handler>): ReturnType<Handler> {
    void Promise.all(args.map(normalizeEvent)).then((events) => {
      events.forEach((event) => {
        const globalContext = event.global_contexts;
        const locationStack = event.location_stack;
        const appId = globalContext?.find(({ _type }) => _type === 'ApplicationContext')?.id;
        const locationStacks = locationStack?.map(({ id }) => id);
        const eventType = event._type;

        console.log([appId, ...locationStacks, eventType].join('>'), { ...event });
      });
    });

    return super.handle(...args);
  }
}
