import { BaseModal, type BaseModalProps } from '@insify/modal';
import { useBreakpoint } from '@insify/react-hooks';
import { animated, easings, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { ChevronLeft, X } from '../../icons';
import { Button } from '../Button';

import * as styles from './Modal.module.css';

import type { UseTransitionProps } from '@react-spring/web';

const CONTENT_TOP_PADDING = 8;

type TransitionDirection = 'forward' | 'backward';

type NavigateFns = {
  forward: () => void;
  backward: () => void;
};

type ModalProps = Omit<BaseModalProps, 'children'> & {
  children: React.ReactNode | ((navigate: NavigateFns) => React.ReactNode[]);
  button?: React.ReactNode | React.ReactNode[];
  footer?: React.ReactNode;
};

// https://www.figma.com/file/ssGmau7DMZsdIX7F6k0lGk/%E2%9A%A1%EF%B8%8F-Design-System?type=design&node-id=245-4009&mode=design&t=ofKIuOSPHQWXBcWo-0
export const Modal: React.FC<ModalProps> = ({ children, onClose, ...props }) => {
  const [showHeaderBorder, setShowHeaderBorder] = useState<boolean>(false);
  const [activePageIndex, setActivePageIndex] = useState<number>(0);
  const [direction, setDirection] = useState<TransitionDirection>('forward');
  const pages = useRef<React.ReactNode[]>([]);
  const isDesktop = useBreakpoint('(min-width: 960px)');
  const offset = typeof window === 'undefined' ? 0 : isDesktop ? 580 : window.innerWidth;
  const scrollPositionPerPage = useRef<number[]>([]);
  const currentPageDiv = useRef<HTMLElement | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const transitions = useTransition(activePageIndex, {
    config: {
      duration: 250,
      easing: easings.easeInOutQuart,
    },
    initial: { position: 'static', transform: 'translateX(0px)', opacity: 1 },
    from: {
      position: 'absolute',
      transform: `translateX(${direction === 'forward' ? '' : '-'}${offset}px)`,
      opacity: 0,
    },
    enter: {
      position: 'static',
      transform: `translateX(0px)`,
      opacity: 1,
    },
    leave: {
      position: 'absolute',
      transform: `translateX(${direction === 'forward' ? '-' : ''}${offset}px)`,
      opacity: 0,
    },
  } as UseTransitionProps);

  useEffect(() => {
    pages.current = typeof children === 'function' ? children({ forward, backward }) : [];
  }, [children]);

  const onRef = (node: HTMLElement | null) => {
    if (node) {
      currentPageDiv.current = node;
    }
  };

  const updateShowHeaderBorder = (scrollableContainer: HTMLElement) => {
    setShowHeaderBorder(scrollableContainer.scrollTop > CONTENT_TOP_PADDING);
  };

  const forward = () => {
    setDirection('forward');
    if (currentPageDiv.current) {
      scrollPositionPerPage.current[activePageIndex] = currentPageDiv.current.scrollTop;
    }
    // Here the currentPageDiv.current refers to the previous page,
    // but after one tick it refers to the new page
    setTimeout(() => {
      if (currentPageDiv.current) {
        updateShowHeaderBorder(currentPageDiv.current);
      }
    });
    // Without that the ESC key will not work after the page transition.
    // Something like that is happening:
    // 1. The user presses the button
    // 2. The focus is on the button
    // 3. The page is changed
    // 4. The button is removed from the DOM
    // 5. The focus now is on the body and not on the modal
    // 6. The next line is fixing the problem by focusing the modal again
    modalRef.current?.focus();
    if (activePageIndex < pages.current.length - 1) setActivePageIndex((index) => index + 1);
  };

  const backward = () => {
    setDirection('backward');
    setTimeout(() => {
      if (currentPageDiv.current) {
        updateShowHeaderBorder(currentPageDiv.current);
        // Restore the scroll position of the previous page
        currentPageDiv.current.scrollTop = scrollPositionPerPage.current[activePageIndex - 1];
      }
    });
    modalRef.current?.focus();
    if (activePageIndex > 0) setActivePageIndex((index) => index - 1);
  };

  const close = () => {
    onClose();
    setActivePageIndex(0);
    setShowHeaderBorder(false);
  };

  const modalScrollHandler: React.UIEventHandler = (event) => {
    const target = event.target as HTMLElement;
    // The scroll event is fired during the page transition, when the reference to the currentPageDiv is updated already
    if (target === currentPageDiv.current) {
      updateShowHeaderBorder(target);
    }
  };
  return (
    <BaseModal
      ref={modalRef}
      className={pages.current.length > 1 && styles.restrictedHeight}
      onClose={close}
      {...props}
    >
      <header data-testid='modal-header' className={clsx(styles.header, showHeaderBorder && styles.withBorder)}>
        <Button
          className={clsx(styles.backButton, activePageIndex > 0 && styles.show)}
          type='button'
          kind='tertiary'
          id='back'
          trackingId='back'
          leftIcon={<ChevronLeft />}
          onClick={backward}
        />
        {!props.locked && (
          <Button
            className={styles.closeButton}
            type='button'
            kind='tertiary'
            id='close'
            data-testid='close-modal'
            trackingId='close'
            leftIcon={<X />}
            onClick={close}
          />
        )}
      </header>
      <main
        data-testid='modal-content'
        className={styles.content}
        style={{
          paddingTop: pages.current.length > 1 ? '0' : CONTENT_TOP_PADDING + 'px',
          paddingLeft: pages.current.length > 1 ? '0' : '16px',
          paddingRight: pages.current.length > 1 ? '0' : '16px',
          paddingBottom: props.button ? '22px' : '32px',
        }}
        // Depending on the children type,
        // the ref and the onScroll handler should be set on the main element.
        // If children is a function, then the animated.div is a scrollable container.
        // If not, then the main element is a scrollable container.
        ref={typeof children !== 'function' ? onRef : undefined}
        onScroll={typeof children !== 'function' ? modalScrollHandler : undefined}
      >
        {typeof children === 'function'
          ? transitions((style, activePageIndex) => (
              <animated.div
                className={styles.animatedWrapper}
                style={{
                  ...style,
                  paddingTop: CONTENT_TOP_PADDING + 'px',
                  paddingLeft: props.button ? '0' : '',
                  paddingRight: props.button ? '0' : '',
                }}
                ref={onRef}
                onScroll={modalScrollHandler}
              >
                <div
                  style={{
                    paddingLeft: props.button ? '16px' : '0',
                    paddingRight: props.button ? '16px' : '0',
                  }}
                >
                  {children({ forward, backward })[activePageIndex]}
                </div>
                {props.button ? (
                  Array.isArray(props.button) ? (
                    props.button[activePageIndex] && (
                      <div className={styles.bottomButton}>{props.button[activePageIndex]}</div>
                    )
                  ) : (
                    <div className={styles.bottomButton}>{props.button}</div>
                  )
                ) : null}
              </animated.div>
            ))
          : children}
      </main>
      {props.footer && (
        <footer data-testid='modal-footer' className={styles.footer}>
          {props.footer}
        </footer>
      )}
    </BaseModal>
  );
};
